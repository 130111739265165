import ServiceIcon from '@material-ui/icons/BorderClear';
import SearchPageIcon from '@material-ui/icons/Search';
import locales from './locales';
import bufferStatus from "./resources/bufferStatus";
import codeType from './resources/codeType';
import deliveryType from './resources/deliveryType';
import directionType from './resources/directionType';
import docStatus from './resources/docStatus';
import docLink from './resources/docLink';
import docLinkType from './resources/docLinkType';
import docStatusSystem from './resources/docStatusSystem';
import emissionType from './resources/emissionType';
import errorModel from './resources/errorModel';
import markingStatus from './resources/markingStatus';
import markingType from './resources/markingType';
import mrkStatusSystem from './resources/mrkStatusSystem';
import objectSendModel from './resources/objectSendModel';
import orderCheckKM from './resources/orderCheckKM';
import orderCloseOrder from './resources/orderCloseOrder';
import orderGetKM from './resources/orderGetKM';
import orderH from './resources/orderH';
import orderM from './resources/orderM';
import taskManager from './resources/taskManager';
import taskManagerErrors from './resources/taskManagerErrors';
import taskManagerLogs from './resources/taskManagerLogs';
import taskManagerTask from './resources/taskManagerTask';
import orderSend from './resources/orderSend';
import ordersPool from './resources/ordersPool';
import orderStatus from './resources/orderStatus';
import orderType from './resources/orderType';
import orderUpdateAllStatuses from './resources/orderUpdateAllStatuses';
import orderUpload from './resources/orderUpload';
import productionType from './resources/productionType';
import queryRequest from './resources/queryRequest';
import requestType from './resources/requestType';
import releaseMethodType from './resources/releaseMethodType';
import requestStatus from './resources/requestStatus';
import serialNumType from './resources/serialNumType';
import serialNumberType from './resources/serialNumberType';
import turnoverType from './resources/turnoverType';
import treeMrkType from './resources/treeMrkType';
import xmlRequestDocument from './resources/xmlRequestDocument';
import xmlRequestTicket from './resources/xmlRequestTicket';
import treeMrkLoadCustom from './resources/treeMrkLoadCustom';
import notificationStatusChange from './resources/notificationStatusChange';
import ismpDocumentType from './resources/ismpDocumentType';
import mrkPackageType from './resources/mrkPackageType';
import mrkPacksType from './resources/mrkPacksType';
import mrkTreeType from './resources/mrkTreeType';
import mrkExStatus from './resources/mrkExStatus';
import orderOmsStatus from './resources/orderOmsStatus';
import funcType from './resources/funcType';
import cisPackageType from './resources/cisPackageType';
import withdrawalType from './resources/withdrawalType';
import xlsBrokerTemplateEnum from './resources/xlsBrokerTemplateEnum';
import docCheckStatus from './resources/docCheckStatus';

import treeMrkkz from './resources/treeMrkKz';
import usageType from './resources/usageType';
import docEdoP from './resources/docEdoP';
import actP from './resources/actP';
import outH from './resources/outH'
import docLoadCustom from './resources/syncAct';
import inputH from './resources/inputH';
import importH from './resources/importH';
import importThirdCountryH from './resources/importThirdCountryH';
import reportH from './resources/reportH'
import outP from './resources/outP';
import inputP from './resources/inputP';
import importP from './resources/importP';
import importThirdCountryP from './resources/importThirdCountryP';
import reportP from './resources/reportP';
import aggrH from './resources/aggrH';
import aggrP from './resources/aggrP';
import aggr from './resources/aggr';
import shipmentGtinData from './resources/shipmentGtinData';
import documentImport from './resources/documentImport';
import documentType from './resources/documentType';
import syncAct from "./resources/syncAct";
import syncReport from "./resources/syncReport";
import checkStatus from './resources/checkStatus';
import status from './resources/status';
import reconciliation from "./resources/reconciliation";
import printLoad from "./resources/printLoad";
import actHIn from "./resources/actHIn";
import actHOut from "./resources/actHOut";
import actH from "./resources/actH";


const resources = [
    syncReport,
    documentImport,
    checkStatus,
    status,

    documentType,

    actH,
    actHIn,
    actHOut,
    actP,
    shipmentGtinData,
    syncAct,

    aggrH,
    aggrP,

    aggr,

    outH,
    outP,

    inputH,
    inputP,

    importH,
    importP,

    importThirdCountryH,
    importThirdCountryP,

    reportH,
    reportP,

    docLoadCustom,

    orderH,
    orderM,
    treeMrkkz,
    docEdoP,
    notificationStatusChange,

    queryRequest,

    taskManager,
    taskManagerErrors,
    taskManagerLogs,
    taskManagerTask,
    reconciliation,
    printLoad,
    errorModel,
    bufferStatus,
    objectSendModel,
    codeType,
    deliveryType,
    directionType,
    docStatus,
    docLink,
    docLinkType,
    docStatusSystem,
    emissionType,
    markingStatus,
    markingType,
    mrkStatusSystem,
    orderCheckKM,
    orderCloseOrder,
    orderGetKM,
    orderSend,
    ordersPool,
    orderStatus,
    orderOmsStatus,
    orderType,
    orderUpdateAllStatuses,
    orderUpload,
    funcType,
    withdrawalType,
    productionType,
    requestType,
    releaseMethodType,
    requestStatus,
    serialNumType,
    serialNumberType,
    turnoverType,
    treeMrkType,
    xmlRequestDocument,
    xmlRequestTicket,
    ismpDocumentType,
    treeMrkLoadCustom,
    mrkPackageType,
    mrkPacksType,
    mrkTreeType,
    mrkExStatus,
    cisPackageType,
    xlsBrokerTemplateEnum,
    docCheckStatus,
    usageType,
];

export default {
    name: 'marking',
    locales,
    resources,
    icon: ServiceIcon,
    customMenuItems: [
        {
            path: '/search-km',
            icon: SearchPageIcon,
            name: 'searchKMPage.title',
        },
    ],
    documentation: {
        swaggerResourcesPath: '/swagger-resources',
    },
};
