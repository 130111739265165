import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BulkDeleteButton,
  EditButton,
  Filter,
  CreateButton,
  ExportButton,
  NumberInput,
  BooleanField,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyleByCheckStatus';
import DateInput from '../../../../components/DateInput';
import exporter from '../../../../utils/exporter';
import CheckDocs from './CheckDocs';
import inputP from '../inputP';
import usageType from '../usageType';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import directionType from '../directionType'
import SendInput from './SendInput';
import ValidateInput from './ValidateInput';
import documentImport from '../documentImport';
import ValidateDocs from "./ValidateDocs";
import ShipmentGtinData from "../shipmentGtinData";


import status from '../status';
import checkStatus from '../checkStatus';
const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} to={`${basePath}/create`} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <documentImport.OpenButton params={{ docType: 'INPUT' }} />
  </Toolbar>
);

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <CheckDocs {...props} />
    <ValidateDocs {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const PButton = (props) => {
  return (
    <inputP.LinkToList
      label={`resources.${resourceConfig.name}.inputP`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};


const Prices = (props) => {
  return (
      <ShipmentGtinData.LinkToList
          label={`resources.${resourceConfig.name}.actPrice`}
          filter={{
            overEntityIds: props.record.id,
          }}
      />
  );
};

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'id'}/>
    <checkStatus.Input source={'checkStatus'}/>
    <DateInput source={'dateSendFrom'} />
    <DateInput source={'dateSendTo'} />
    <DateInput source={'docDate'} />
    <TextInput source={'docNum'} />
    <TextInput source={'idDoc'} />
    <TextInput source={'reasonIntroduced'} />
    <TextInput source={'senderBin'} />
    <TextInput source={'senderName'} />
    <status.Input source={'status'}/>
    <TextInput source={'numdoc'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    actions={<ResourceActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    sort={{ field: 'id', order: 'DESC' }}
    exporter={exporter}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />
      <TextField source={'idDoc'} />
      <TextField source={'reasonIntroduce'} />
      <TextField source={'dateSend'} />
      <TextField source={'docNum'} />
      <TextField source={'docDate'} />
      <status.Field source={'status'} />
      <checkStatus.Field source={'checkStatus'} />
      <SendInput/>
      <objectSendModel.LinkToRelatedList  type={(record) => ({ type: record.docType })} />
      <errorModel.LinkToRelatedList  type={(record) => ({ type: record.docType })} />
      <PButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
            <NumberInput source={'id'}/>
            <TextInput source={'addInfo'}/>
            <checkStatus.Input source={'checkStatus'}/>
            <TextInput source={'comment'}/>
            <DateInput
                source={'dateSend'}
                label={`resources.${resourceConfig.name}.fields.dateSend`}
            />
            <DateInput
                source={'docDate'}
                label={`resources.${resourceConfig.name}.fields.docDate`}
            />
            <TextInput source={'docName'}/>
            <TextInput source={'docNum'}/>
            <TextInput source={'docType'}/>
            <NumberInput source={'idDoc'}/>
            <TextInput source={'reasonIntroduce'}/>
            <TextInput source={'senderBin'}/>
            <status.Input source={'status'}/>
            <TextInput source={'numdoc'}/>
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} title={<ResourceTitle />} {...props}>
    <SimpleForm>
    <NumberInput source={'id'}/>
            <TextInput source={'addInfo'}/>
            <checkStatus.Input source={'checkStatus'}/>
            <TextInput source={'comment'}/>
            <DateInput
                source={'dateSend'}
                label={`resources.${resourceConfig.name}.fields.dateSend`}
            />
            <DateInput
                source={'docDate'}
                label={`resources.${resourceConfig.name}.fields.docDate`}
            />
            <TextInput source={'docName'}/>
            <TextInput source={'docNum'}/>
            <TextInput source={'docType'}/>
            <NumberInput source={'idDoc'}/>
            <TextInput source={'reasonIntroduce'}/>
            <TextInput source={'senderBin'}/>
            <status.Input source={'status'}/>
            <TextInput source={'numdoc'}/>
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      expirationDate: {
        type: 'dateTime',
      },
      docDate:{
        type: 'date'
      },
      dateSend: {
        type: 'dateTime'
      },
      dateSendFrom: {
        type: 'dateTime'
      },
      dateSendTo: {
        type: 'dateTime'
      }
    },
  },
};
