import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции вывода из третьих стран |||| Позиции вывода из третьих стран',
      fields: {
        id: '#',
        sntin: 'Код маркировки',
        code: "КМ",
        aggregateCode: "Код агрегата",
        typePackage: "Тип упаковки"
      },
    },
  },
};
