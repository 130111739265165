import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DRAFT: 'Черновик',
        REJECTED: 'Отклонен',
        W_VERIFICATION: 'Ожидает подтверждения',
        ACCEPTED: 'Принят',
        CHECK_ERROR: 'Ошибка при проверке',
        WITHDRAWN: 'Отозван',
        CANCELED: 'Аннулирован',
        СULC_RESULTS: 'Расчет итогов',
        CLARIFICATION_REQUESTED: 'Запрошено уточнение',
        FORMED: 'Сформирован',
        IN_PROGRESS: 'Проверяется',
        CHECKED_OK: 'Обработан',
        CHECKED_NOT_OK: 'Не обработан',
        ERROR: 'Ошибка',
      },
    },
  },
};
