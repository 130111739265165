import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции ввода в оборот |||| Позиции ввода в оборот',

      fields: {
        id: '#',
        sntin: 'Код маркировки',
        code: "КМ",
        aggregateCode: "Код агрегата",
        typePackage: "Тип упаковки"
      },
    },
  },
};
