import React, {Fragment} from 'react';
import {
    BooleanInput,
    BulkDeleteButton,
    ChipField,
    Create,
    CreateButton,
    Datagrid,
    Edit,
    EditButton,
    ExportButton,
    Filter,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
    translate,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyleByCheckStatus';
import DateInput from '../../../../components/DateInput';
import exporter from '../../../../utils/exporter';
import CheckDocs from './CheckDocs';
import docLoadCustom from '../syncAct';
import markingStatus from '../markingStatus';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const listStyles = {};

const ResourceActions = ({
                             bulkActions,
                             basePath,
                             currentSort,
                             displayedFilters,
                             exporter,
                             filters,
                             filterValues,
                             onUnselectItems,
                             resource,
                             selectedIds,
                             showFilter,
                             total,
                         }) => (
    <Toolbar>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <CreateButton basePath={basePath} to={`${basePath}/create`}/>
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <docLoadCustom.OpenButton/>
    </Toolbar>
);

const ResourceBulkActionButtons = (props) => (
    <Fragment>
        <CheckDocs {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);


const ResourceListFilter = (props) => (
    <Filter {...props}>
        <TextInput source={'ids'}/>
        <TextInput source={'cis'}/>
        <TextInput source={'packageType'}/>
        <DateInput source={'producedDateBefore'} showTime/>
        <DateInput source={'producedDateAfter'} showTime/>
        <TextInput source={'ownerBin'}/>
        <TextInput source={'ownerName'}/>
        <markingStatus.Input source={'status'}/>
        <TextInput source={'producerBin'}/>
        <TextInput source={'producerName'}/>
        <BooleanInput source={'processed'}/>
    </Filter>
);

const ResourceList = withStyles(listStyles)(({classes, ...props}) => (
    <List
        {...props}
        filters={<ResourceListFilter/>}
        actions={<ResourceActions/>}
        bulkActionButtons={<ResourceBulkActionButtons/>}
        sort={{field: 'id', order: 'DESC'}}
        exporter={exporter}>
        <Datagrid rowStyle={documentRowStyle}>
            <TextField source={'id'}/>
            <TextField source={'cis'}/>
            <TextField source={'packageType'}/>
            <TextField source={'status'}/>
            <TextField source={'ownerBin'}/>
            <EditButton/>
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({record, translate}) => (
    <span>
    {translate(`resources.${resourceConfig.name}.name`, {smart_count: 1})} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source={'cis'}/>
            <TextInput source={'packageType'}/>
            <DateInput source={'producedDate'} showTime/>
            <TextInput source={'ownerBin'}/>
            <TextInput source={'ownerName'}/>
            <markingStatus.Input source={'status'}/>
            <TextInput source={'producerBin'}/>
            <TextInput source={'producerName'}/>
            <TextInput source={'aggregateCode'}/>
            <BooleanInput source={'processed'}/>
            <TextInput source={'parent'}/>
        </SimpleForm>
    </Create>
);

const ResourceEdit = (props) => (
    <Edit redirect={null} title={<ResourceTitle/>} {...props}>
        <SimpleForm>
            <TextField source={'id'}/>
            <TextInput source={'cis'}/>
            <TextInput source={'packageType'}/>
            <DateInput source={'producedDate'} showTime/>
            <TextInput source={'ownerBin'}/>
            <TextInput source={'ownerName'}/>
            <markingStatus.Input source={'status'}/>
            <TextInput source={'producerBin'}/>
            <TextInput source={'producerName'}/>
            <TextInput source={'aggregateCode'}/>
            <TextInput source={'parent'}/>
            <BooleanInput source={'processed'}/>
        </SimpleForm>
    </Edit>
);

const ResourceStringField = ({record}) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
    <ReferenceField reference={resourceConfig.name} {...props}>
        <ResourceStringField/>
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField reference={resourceConfig.name} {...props}>
        <SingleFieldList>
            <ChipField source={'id'}/>
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput reference={resourceConfig.name} {...props}>
        <SelectInput optionText={resourceToString}/>
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput reference={resourceConfig.name} {...props}>
        <SelectArrayInput optionText={resourceToString}/>
    </ReferenceArrayInput>
);

export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,

    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name,
        icon: ResourceIcon,
    },
    locales: resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
            expirationDate: {
                type: 'dateTime',
            },
            producedDate:{
                type: 'dateTime',
            },
            producedDateBefore: {
                type: 'dateTime',
            },
            producedDateAfter: {
                type: 'dateTime',
            },
        },
    },
};
