export default {
  enums: [
    { id: 'CREATE_UIT_FROM_ACT_POSITION_TASK' },
    { id: 'SYNC_IN_DOCS_TASK'},
    { id: 'DOCUMENT_CHECKER_TASK'},
    { id: 'SEND_DOC_TASK'},
    { id: 'AGGR_GET_CHILD_TASK'},
    { id: 'CHECK_KM_STATUS_TASK'},
    { id: 'GET_GTIN_FOR_DOC_TASK'}
  ],
};
